import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState, useManagedTeams } from 'context/GlobalState';
import {
  useCurrencyDisplayMode,
  useSupportedLanguages,
} from 'domains/organization/hooks';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { memberTitles, NetworkErrorCode } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { Role } from 'services/rbac';
import {
  formatMoney,
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
  validateCardholderFullName,
  validateEmail,
} from 'services/utils';

interface AddTeamMembersDialogForm {
  teamId: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  teamId: string;
  firstName: string;
  lastName: string;
  onClose: () => void;
  onSuccess: () => void;
}

const InviteMemberStep = ({
  teamId,
  firstName,
  lastName,
  onClose,
  onSuccess,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization, subscriptionPlan },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const supportedLanguages = useSupportedLanguages();
  const currencyDisplay = useCurrencyDisplayMode();
  const formik = useFormik<AddTeamMembersDialogForm>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      teamId,
      title: '',
      firstName,
      lastName,
      email: '',
    },
    onSubmit: async (
      { teamId: selectedTeamId, ...rest },
      { setSubmitting, setErrors }
    ) => {
      try {
        const values = trimObjValues(rest);
        await api.inviteMember({
          ...(values as typeof rest),
          title: values.title || null,
          roles: [Role.cardholder],
          languageCode: supportedLanguages[0].code,
          organizationId: organization!.id,
          teamIds: [selectedTeamId],
        });
        if (!mounted.current) return;
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.memberLimitExceeded
        ) {
          enqueueSnackbar(
            t('addTeamMembersDialog.inviteMember.memberLimitExceeded'),
            {
              variant: 'error',
            }
          );
        } else if (
          getNetworkErrorCode(error) === NetworkErrorCode.userAlreadyExists
        ) {
          setErrors({
            email: t('addTeamMembersDialog.inviteMember.userExistsError'),
          });
        } else {
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          logError(error);
        }
      }
    },
  });
  const selectedTeam = useMemo(
    () => managedTeams.find((item) => item.id === formik.values.teamId),
    [managedTeams, formik.values.teamId]
  );

  const {
    firstNameNotPrintableError,
    lastNameNotPrintableError,
    fullNameTooLongError,
  } = validateCardholderFullName(
    formik.values.firstName,
    formik.values.lastName
  );

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email
  );

  const shouldShowInvitationTip =
    subscriptionPlan.activeAndInvitedMembersCount >=
      subscriptionPlan.freeMembersCount &&
    subscriptionPlan.additionalUserFee &&
    subscriptionPlan.additionalUserFee.value > 0;

  const isSubmitDisabled =
    !formik.values.firstName.trim() ||
    !formik.values.lastName.trim() ||
    !formik.values.email.trim() ||
    !!firstNameNotPrintableError ||
    !!lastNameNotPrintableError ||
    !!fullNameTooLongError ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  return (
    <>
      <DialogTitle>
        {t('addTeamMembersDialog.inviteMember.inviteMember')}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="invite-member-form" noValidate>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <FormControl disabled={formik.isSubmitting} fullWidth>
                <InputLabel id="member-title-select-label">
                  {t('addTeamMembersDialog.inviteMember.title')}
                </InputLabel>
                <Select<string>
                  labelId="member-title-select-label"
                  renderValue={(selected) => t(`memberTitles.${selected}`)}
                  {...formik.getFieldProps('title')}
                >
                  <MenuItem value="">-</MenuItem>
                  {memberTitles.map((title) => (
                    <MenuItem key={title} value={title}>
                      {t(`memberTitles.${title}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('firstName')}
                label={t('addTeamMembersDialog.inviteMember.firstName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
                error={!!firstNameNotPrintableError || !!fullNameTooLongError}
                helperText={firstNameNotPrintableError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('lastName')}
                label={t('addTeamMembersDialog.inviteMember.lastName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
                error={!!lastNameNotPrintableError || !!fullNameTooLongError}
                helperText={lastNameNotPrintableError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('email')}
                label={t('addTeamMembersDialog.inviteMember.email')}
                disabled={formik.isSubmitting}
                error={!!invalidEmailError || !!emailTooLongError}
                helperText={invalidEmailError || emailTooLongError}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl disabled={formik.isSubmitting} fullWidth>
                <InputLabel id="team-select-label">
                  {t('addTeamMembersDialog.inviteMember.team')}
                </InputLabel>
                <Select<string>
                  labelId="team-select-label"
                  renderValue={() => selectedTeam?.name || ''}
                  {...formik.getFieldProps('teamId')}
                >
                  {managedTeams.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {!!fullNameTooLongError && (
            <FormHelperText error>{fullNameTooLongError}</FormHelperText>
          )}

          {shouldShowInvitationTip && (
            <Box mt={3}>
              <Alert severity="warning">
                <Trans
                  i18nKey="tooltips.thresholdExceededInPremiumOrEnterprisePlans"
                  components={{
                    RedAndBoldText: <b />,
                  }}
                  values={{
                    fee: formatMoney(
                      subscriptionPlan.additionalUserFee,
                      i18n.language,
                      { currencyDisplay, fractionalPart: true }
                    ),
                  }}
                />
              </Alert>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="invite-member-form"
          type="submit"
        >
          {t('addTeamMembersDialog.inviteMember.sendInvite')}
        </Button>
      </DialogActions>
      {formik.isSubmitting && <LoaderWithOverlay size={32} thickness={3} />}
    </>
  );
};

export default InviteMemberStep;
