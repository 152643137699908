import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const HELP_CENTER_BASE_URL = 'https://help.getpliant.com';

// Only English and German are supported currently
const getHelpCenterUrls = (language: string) => {
  if (language === 'de') {
    const HELP_CENTER_URL = `${HELP_CENTER_BASE_URL}/de`;
    return {
      HELP_CENTER_URL,
      HELP_CENTER_CARD_CONTROLS_URL: `${HELP_CENTER_URL}/articles/7325380-transaktionskategorien-erlauben-sperren`,
      HELP_CENTER_CARD_REQUESTS_URL: `${HELP_CENTER_URL}/articles/6460960-anfragen-einer-karte`,
      HELP_CENTER_CARD_TYPES_URL: `${HELP_CENTER_URL}/articles/6090567-kartentypen-physische-und-virtuelle-karten`,
      HELP_CENTER_TEAMS_URL: `${HELP_CENTER_URL}/articles/6454972-einrichten-und-verwalten-von-teams`,
      HELP_CENTER_TEAM_BUDGETS_URL: `${HELP_CENTER_URL}/articles/6463998-team-budgets`,
      HELP_CENTER_MANAGER_TX_REVIEWS_URL: `${HELP_CENTER_URL}/articles/8329619-transaction-review`, // there is no de link
      HELP_CENTER_PLIANT_EARTH_URL: `${HELP_CENTER_URL}/articles/6595739-pliant-earth`,
      HELP_CENTER_ACCOUNTING_MERCHANT_RULES_URL: `${HELP_CENTER_URL}/articles/6788115-handlerspezifische-buchhaltungsregeln`,
      HELP_CENTER_MONTHLY_TRANSACTION_REPORT_URL: `${HELP_CENTER_URL}/articles/7836485-monatlicher-transaktionsbericht`,
      HELP_CENTER_RECEIPT_NUMBER_IN_DATEV_EXPORT_URL: `${HELP_CENTER_URL}/articles/6838687-belegnummer-im-datev-export`,
      HELP_CENTER_RECEIPT_DATE_IN_DATEV_EXPORT_URL: `${HELP_CENTER_URL}/articles/8769857-belegdatum-exporteinstellungen`,
      HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL: `${HELP_CENTER_URL}/articles/6865126-transaktionen-nach-lexoffice-exportieren`,
      HELP_CENTER_ACTIVE_NFE_URL: `${HELP_CENTER_URL}/articles/6829855-was-ist-ein-aktiver-nicht-finanzieller-rechtstrager-nfe`,
      HELP_CENTER_RECEIPT_MANAGEMENT_URL: `${HELP_CENTER_URL}/articles/7325327-einstellungen-zum-belegmanagement`,
      HELP_CENTER_MEMBER_ROLES_URL: `${HELP_CENTER_URL}/articles/6090556-member-roles-and-permissions`,
      HELP_CENTER_BMD_EXPORT_URL: `${HELP_CENTER_URL}/articles/6316774-exporting-to-bmd`, // there is no de link
      HELP_CENTER_CUSTOM_FIELDS_URL: `${HELP_CENTER_URL}/articles/9244962-benutzerdefinierte-felder`,
      HELP_CENTER_PAIRING_MOBILE_DEVICE_URL: `${HELP_CENTER_URL}/articles/6452385-ein-mobilgerat-verbinden-2fa`,
      HELP_CENTER_PAIRING_SECURITY_KEY_URL: `${HELP_CENTER_URL}/articles/9571318-security-key-authentication`,
    };
  }

  const HELP_CENTER_URL = `${HELP_CENTER_BASE_URL}/en`;
  return {
    HELP_CENTER_URL,
    HELP_CENTER_CARD_CONTROLS_URL: `${HELP_CENTER_URL}/articles/7325380-transaction-category-controls`,
    HELP_CENTER_CARD_REQUESTS_URL: `${HELP_CENTER_URL}/articles/6460960-requesting-a-card`,
    HELP_CENTER_CARD_TYPES_URL: `${HELP_CENTER_URL}/articles/6090567-card-types-physical-and-virtual-cards`,
    HELP_CENTER_TEAMS_URL: `${HELP_CENTER_URL}/articles/6454972-setting-up-and-managing-teams`,
    HELP_CENTER_TEAM_BUDGETS_URL: `${HELP_CENTER_URL}/articles/6463998-team-budgets`,
    HELP_CENTER_MANAGER_TX_REVIEWS_URL: `${HELP_CENTER_URL}/articles/8329619-transaction-review`,
    HELP_CENTER_PLIANT_EARTH_URL: `${HELP_CENTER_URL}/articles/6595739-pliant-earth`,
    HELP_CENTER_ACCOUNTING_MERCHANT_RULES_URL: `${HELP_CENTER_URL}/articles/6788115-merchant-based-accounting-rules`,
    HELP_CENTER_MONTHLY_TRANSACTION_REPORT_URL: `${HELP_CENTER_URL}/articles/7836485-monthly-transactions-report`,
    HELP_CENTER_RECEIPT_NUMBER_IN_DATEV_EXPORT_URL: `${HELP_CENTER_URL}/articles/6838687-receipt-number-in-datev-export`,
    HELP_CENTER_RECEIPT_DATE_IN_DATEV_EXPORT_URL: `${HELP_CENTER_URL}/articles/8769857-receipt-date-export-settings`,
    HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL: `${HELP_CENTER_URL}/articles/6865126-exporting-transactions-to-lexoffice`,
    HELP_CENTER_ACTIVE_NFE_URL: `${HELP_CENTER_URL}/articles/6829855-what-is-an-active-non-financial-entity-nfe`,
    HELP_CENTER_RECEIPT_MANAGEMENT_URL: `${HELP_CENTER_URL}/articles/7325327-receipt-management-settings`,
    HELP_CENTER_MEMBER_ROLES_URL: `${HELP_CENTER_URL}/articles/6090556-mitarbeiterrollen-und-berechtigungen`,
    HELP_CENTER_BMD_EXPORT_URL: `${HELP_CENTER_URL}/articles/6316774-exporting-to-bmd`,
    HELP_CENTER_CUSTOM_FIELDS_URL: `${HELP_CENTER_URL}/articles/9244962-custom-fields`,
    HELP_CENTER_PAIRING_MOBILE_DEVICE_URL: `${HELP_CENTER_URL}/articles/6452385-pairing-a-mobile-device-2fa`,
    HELP_CENTER_PAIRING_SECURITY_KEY_URL: `${HELP_CENTER_URL}/articles/9571318-security-key-authentication`,
  };
};

const getBookingSupportUrls = (language: string) => {
  if (language === 'de') {
    return {
      BOOK_CONSULTING_SESSION: 'https://calendly.com/customer-success-pliant',
      BOOK_GENERAL_ONBOARDING_WEBINAR:
        'https://app.livestorm.co/pliant/general-onboarding-1?s=41742816-d898-4468-94a3-c661ac4c88e5',
      BOOK_ACCOUNTING_ONBOARDING_WEBINAR:
        'https://app.livestorm.co/pliant/accounting-onboarding-german?s=4a3bded4-828c-4a98-bb2e-539586bedc94',
    };
  }

  return {
    BOOK_CONSULTING_SESSION: 'https://calendly.com/customer-success-pliant',
    BOOK_GENERAL_ONBOARDING_WEBINAR:
      'https://app.livestorm.co/pliant/general-onboarding-english?s=f2ed157a-e050-4cb6-a7eb-92e39e40bcfe',
    BOOK_ACCOUNTING_ONBOARDING_WEBINAR:
      'https://app.livestorm.co/pliant/accounting-onboarding-german?s=4a3bded4-828c-4a98-bb2e-539586bedc94',
  };
};

const getMobileAppUrls = (language: string) => {
  return {
    PLIANT_APP_STORE_URL: `https://apps.apple.com/${
      language === 'en' ? 'us' : language
    }/app/pliant/id1535026245`,
    PLIANT_GOOGLE_PLAY_URL: `https://play.google.com/store/apps/details?id=com.getpliant&hl=${language}`,
    COMMERZBANK_APP_STORE_URL: `https://apps.apple.com/${
      language === 'en' ? 'us' : language
    }/app/pliant-x-commerzbank/id6502640655`,
    COMMERZBANK_GOOGLE_PLAY_URL: `https://play.google.com/store/apps/details?id=com.getpliant.commerzbank&hl=${language}`,
  };
};

const useUrls = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(
    () => ({
      FAQ_URL: `https://www.getpliant.com/${language}/faq`,
      SUPPORT_REQUEST_URL:
        'https://share.hsforms.com/1RRzgplmkR_Ot33ceUKbEtgbu60f',
      ...getBookingSupportUrls(language),
      ...getHelpCenterUrls(language),
      ...getMobileAppUrls(language),
    }),
    [language]
  );
};

export type HelpCenterUrlsType = ReturnType<typeof getHelpCenterUrls>;
export default useUrls;
